// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-template-docs-md-js": () => import("/home/headless/node/src/templates/template-docs-md.js" /* webpackChunkName: "component---src-templates-template-docs-md-js" */),
  "component---src-pages-404-js": () => import("/home/headless/node/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-docs-index-js": () => import("/home/headless/node/src/pages/docs/index.js" /* webpackChunkName: "component---src-pages-docs-index-js" */),
  "component---src-pages-index-js": () => import("/home/headless/node/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

